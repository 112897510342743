
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import RatingCell from '@/components/dataTables/cell/RatingCell.vue'

@Component({
  components: { RatingCell },
})
  export default class GenioQualification extends Vue {
  @Prop({ type: [String, Number, Object, Array] }) value!: any

  get rating () {
    const { value } = this
    return value?.metadata?.average || 0
  }
  }
